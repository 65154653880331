import React, { useState, useCallback, useMemo, useRef } from 'react';
import { Popover } from 'devextreme-react/popover';
import './style-banner.css';
import { Button, ButtonTypes } from 'devextreme-react/button';
import { TextArea } from 'devextreme-react';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopoverVisible: false
        };

        this.showPopover = this.showPopover.bind(this);
        this.hidePopover = this.hidePopover.bind(this);
    }

    showPopover() {
        this.setState({
            isPopoverVisible: true
        });
    }

    hidePopover() {
        this.setState({
            isPopoverVisible: false
        });
    }

    render() {

        return (
            <div id="rcorners2" class="header">
                <p>WARNING TECNICO :
                    <a id="linkWarning" onClick={this.showPopover} href="javascript:void(0);"><font color="white"> Clicca qui per visualizzare</font></a>
                </p>
                <Popover
                    target="#linkWarning"
                    position="top"
                    title="DETTAGLIO WARNING TECNICO"
                    showTitle={true}
                    showCloseButton={false}
                    width={550}
                    height={400}
                    shading={true}
                    visible={this.state.isPopoverVisible}
                    shadingColor="rgba(0, 0, 0, 0.5)"
                >
                    <TextArea text={this.props.text} className='textArea'>

                    </TextArea>
                    <div id="bottomButtons">
                        <div id="buttons">
                            <Button
                                id='CHIUDI'
                                text="Chiudi"
                                type="default"
                                stylingMode="text"
                                onClick={this.hidePopover}
                            />
                        </div>
                    </div>
                </Popover>

            </div>
        )
    }
}

export default App;