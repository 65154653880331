/* eslint-disable import/no-anonymous-default-export */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm } from './components'; 

import './login-style.scss';

export default function () {
  return (
    <Switch>
          <Route exact path='/login' >

              <div className="maincontainer">
                  <div className="container-fluid">
                      <div className="row no-gutter">
                          <div className="col-md-6 d-none d-md-flex bg-image"></div>
                          <div className="col-md-6 bg-light">
                              <div className="login d-flex align-items-center py-5">
                                  <div className="container">
                                      <div className="row">
                                          <div className="col-lg-10 col-xl-7 mx-auto">
                                              <h3 className="display-4">Vulcano Portal</h3>
                                              <p className="text-muted mb-4">Accesso portale supervisione impianti termici</p>
                                             
                                              <LoginForm />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

      </Route>
      <Route exact path='/create-account' >
        <SingleCard title="Sign Up">
          <CreateAccountForm />
        </SingleCard>
      </Route>
      <Route exact path='/reset-password' >
        <SingleCard
          title="Reset Password"
          description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
        >
          <ResetPasswordForm />
        </SingleCard>
      </Route>
      <Route exact path='/change-password/:recoveryCode' >
        <SingleCard title="Change Password">
          <ChangePasswordForm />
        </SingleCard>
      </Route>
      <Redirect to={'/login'} />
    </Switch>
  );
}
