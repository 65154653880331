import React, { useState, setState, useEffect, createContext, useContext, useCallback, useMemo, useRef } from 'react';
import appInfo from '../../app-info';
import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';
import { custom } from 'devextreme/ui/dialog';
import DataSource from 'devextreme/data/data_source';
import { ButtonGroup } from 'devextreme-react/button-group';
import ArrayStore from 'devextreme/data/array_store';
import Row from 'devextreme/ui/data_grid';
import { alert } from "devextreme/ui/dialog"
import notify from 'devextreme/ui/notify';
import DataGrid, {
    Scrolling,
    Column,
    Pager,
    Paging,
    FilterRow,
    FilterPanel,
    StateStoring,
    HeaderFilter,
    Button,
    GroupPanel,
    ColumnChooser,
    Editing,
    Lookup,
    Toolbar,
    RequiredRule,
} from 'devextreme-react/data-grid';
import 'devextreme-react/text-area';

import { Tooltip } from 'devextreme-react/tooltip';
import ScrollView from 'devextreme-react/scroll-view';
import Guid from 'devextreme/core/guid';

import ButtonToolbar from 'devextreme-react/button';

import Form, { Item, EmptyItem, TabbedItem, Tab, TabPanelOptions, StringLengthRule, GroupItem, Label } from 'devextreme-react/form';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import { CustomRule, PatternRule } from 'devextreme-react/validator';

import ColorCell from './ColorCell.js';
import { useAuth } from '../../contexts/auth';
import { useLocation } from 'react-router-dom'
import { createStore } from "devextreme-aspnet-data-nojquery";
import { FileUploader } from 'devextreme-react/file-uploader';
import { FileUploaderForm } from '../../components';

import './ElencoRichieste.scss';
import { Template } from 'devextreme-react';



import {
    validationRules,
    positionEditorOptions,
    notesEditorOptions,
    phoneEditorOptions
} from './../../ValidationRulesAndEditorOptions.js';
import validationEngine from 'devextreme/ui/validation_engine';





export default function App(props) {

    const validationGroupName = "gridForm";

    const statusDefaultSelected = ['10', '18', '3', '4', '8'];

    const url = appInfo.urlWebApi;
    const { user } = useAuth();
    const location = useLocation();
    var extraParam;

    class pageInfo {
        constructor(pageType, pageTypeUrl, pageTitle) {
            this.pageType = pageType;
            this.pageTypeUrl = pageTypeUrl;
            this.pageTitle = pageTitle;
        }
    }

    const gridRef = useRef();

    const [{ isNewRecord, visible, newGuid }, setPopupState] = useState({});
    const [formData, setData] = useState(null);
    const [showTab, setShow] = useState(false);

    function getPageInfo() {
        var info;

        switch (location.pathname) {
            case "/richiesteIntervento":
                info = new pageInfo("1", "INTERVENTI", "Elenco richieste interventi");
                break;
            case "/richiestePreventivi":
                info = new pageInfo("2", "PREVENTIVI", "Elenco richieste preventivi");
                break;
            default:
                info = null;
        }
        return info;
    }


    const store = new CustomStore({
        key: 'ID',
        load: (loadOptions) => {
            return axios.get(url + '/api/Richieste/Get?', { params: { internalKey: user.internalKey, userType: user.userType, id: user.id, type: getPageInfo().pageType } });
        },
        insert: (values) => {
            return axios.post(url + '/api/Richieste/Post?', null, { params: { values: JSON.stringify(values), internalKey: user.internalKey, userType: user.userType, userid: user.id, type: getPageInfo().pageType, newid: newGuid } });
        },
        update: (key, values) => {
            return axios.put(url + '/api/Richieste/Put?', null, { params: { key: key, values: JSON.stringify(values), internalKey: user.internalKey } });
        },
        remove: (key) => {
            return axios.delete(url + '/api/Richieste/Delete', { params: { key: key, internalKey: user.internalKey } });
        },
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    const dataSource = new DataSource({
        store: store,
        refreshMode: 'reshape'
    })


    const impianti = createStore({
        key: 'Value',
        loadUrl: `${url}/api/Richieste/IMPIANTILookup?internalKey=${user.internalKey}&userType=${user.userType}&id=${user.id}`,
        onBeforeSend(method, ajaxOptions) {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    const tipiUrgenza = createStore({
        key: 'Value',
        loadUrl: `${url}/api/Richieste/AN_TIPI_URGENZALookup?internalKey=${user.internalKey}`,
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    const tipiStato = createStore({
        key: 'Value',
        loadUrl: `${url}/api/Richieste/AN_WF_STATILookup?internalKey=${user.internalKey}`,
        onBeforeSend(method, ajaxOptions) {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    const tipiPagamento = createStore({
        key: 'Value',
        loadUrl: `${url}/api/Richieste/AN_TIPI_PAGAMENTOLookup?internalKey=${user.internalKey}`,
        onBeforeSend(method, ajaxOptions) {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    const titoliPersonali = createStore({
        key: 'Value',
        loadUrl: `${url}/api/Richieste/AN_TITOLI_PERSONALILookup?internalKey=${user.internalKey}`,
        onBeforeSend(method, ajaxOptions) {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    function onAllowEditing(e) {
        return (e.row.data.WF_STATO_CALC === 10);
    }
    function onAllowDeleting(e) {
        return (e.row.data.WF_STATO_CALC === 10);
    }

    // ALLEGATI

    const storeAllegati = new CustomStore({
        key: 'ID',
        load: (loadOptions) => {
            return axios.get(url + '/api/RichiesteAllegati/Get', { params: { internalKey: user.internalKey, idRichiesta: formData.ID, newid: newGuid } });
        },
        remove: (data) => {
            return axios.delete(url + '/api/RichiesteAllegati/Delete', { params: { key: data.ID, internalKey: user.internalKey, idRichiesta: formData.ID, newid: newGuid, namefile: data.NAME, tempPath: data.temp } });
        },
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    const dataSourceAllegati = new DataSource({
        store: storeAllegati,
        refreshMode: 'reshape',
        // requireTotalCount: true,
    });


    function base64ToArrayBuffer(base64) {
        var binaryString = atob(base64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    async function GetDocumento(data) {
        if (user) {
            const instance = axios.create({
                baseURL: url,
                timeout: 3000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            await instance.get('/api/RichiesteAllegati/DownloadFile', {
                params: { idAllegato: data.ID, internalKey: user.internalKey, idRichiesta: formData.ID, newid: newGuid, namefile: data.NAME, tempPath: data.temp }
            })
                .then(function (response) {
                    var bytes = base64ToArrayBuffer(response.data.dataObject);

                    const url = window.URL.createObjectURL(new Blob([bytes]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', response.data.name); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(function () {
                    // imgLogo = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });
        }
        else {
            return null;
        }
    }

    function downloadFile(e) {
        GetDocumento(e.row.data);
    }




    // FINE ALLEGATI

    //INIT EDIT FORM

    async function GetTipoPagamentoByCodice(data) {
        if (user) {
            const instance = axios.create({
                baseURL: url,
                timeout: 3000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            var value = null;

            await instance.get('/GetTipoPagamentobyCodice', {
                params: {
                    internalKey: user.internalKey,
                    codice: data
                }
            })
                .then(function (response) {
                    value = response.data;
                })
                .catch(function () {
                    // imgLogo = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });

            return value;
        }
        else {
            return null;
        }
    }

    async function GetImpiantoById(data) {
        if (user) {
            const instance = axios.create({
                baseURL: url,
                timeout: 3000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            var value = null;

            await instance.get('/api/Richieste/GetImpiantoById', {
                params: {
                    internalKey: user.internalKey,
                    idImpianto: data
                }
            })
                .then(function (response) {
                    value = response.data;
                })
                .catch(function () {
                    // imgLogo = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });

            return value;
        }
        else {
            return null;
        }
    }

    async function CheckHasWarningAmministrativo(idImpianto = null) {
        if (user) {
            const instance = axios.create({
                baseURL: url,
                timeout: 3000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            var value = null;

            await instance.get('/api/Richieste/CheckHasWarningAmmnistrativoByUser', {
                params: {
                    internalKey: user.internalKey,
                    userType: user.userType,
                    id: user.id,
                    idImpianto: idImpianto
                }
            })
                .then(function (response) {
                    value = response.data;
                })
                .catch(function () {
                    // imgLogo = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });

            return value;
        }
        else {
            return null;
        }
    }

    async function changeIndirizzoInquilino(data) {

        var impianto = null;

        await GetImpiantoById(data).then(function (result) {
            impianto = result;
        });

        formData.INDIRIZZO = impianto.INDIRIZZO;
        formData.CIVICO = impianto.CIVICO;
        formData.CAP = impianto.CAP;
        formData.COMUNE = impianto.COMUNE;
        formData.PROVINCIA = impianto.PROVINCIA;
    }


    const showPopup = useCallback(async (isNewRecord, formData) => {
        var newGuid = new Guid().toString();
        var idTipoPagamentoCondomino = 0;

        if (formData.ID_TIPO_PAGAMENTO) {
            await GetTipoPagamentoByCodice('PG003').then(function (result) {
                idTipoPagamentoCondomino = result;
            });
        }

        setShow(formData.ID_TIPO_PAGAMENTO == idTipoPagamentoCondomino);
        setData(formData);
        setPopupState({ isNewRecord, visible: true, newGuid });
    }, []);

    const hidePopup = useCallback(() => {
        setPopupState({ visible: false });
    }, []);

    const confirmChanges = useCallback(async () => {
        const result = validationEngine.validateGroup(validationGroupName);

        if (!result.isValid)
            return;

        var idTipoPagamento = 0;

        await GetTipoPagamentoByCodice('PG003').then(function (result) {
            idTipoPagamento = result;
        });

        var checkInquilino = true;

        if (formData.ID_TIPO_PAGAMENTO == idTipoPagamento) {
            checkInquilino = ((formData.RAGIONE_SOCIALE || '').toString() + (formData.COGNOME || '').toString()) != '';
            if (checkInquilino) checkInquilino = ((formData.PIVA || '').toString() + (formData.CODFISC || '').toString()) != '';

        }

        if (!checkInquilino) {
            let customDeleteConfirmDialog = custom({
                showTitle: false,
                messageHtml: "Per procedere col salvataggio, completare i dati aggiuntivi dell'inquilino.",
                buttons: [{
                    text: "Ok",
                    onClick: () => true
                }]
            })

            await customDeleteConfirmDialog.show().then((dialogResult) => {
                if (dialogResult) {
                    return;
                }
            }).then(function () {
                return;
            })
        }
        else {
            var resultDati = null;

            if (isNewRecord) {
                resultDati = await store.insert(formData);
            }
            else {
                resultDati = await store.update(formData["ID"], formData);
            }

            if (resultDati != null) {
                if (resultDati.data.result == 99) {
                    notify(resultDati.data.message, 'error', 2000);
                } else {
                    await store.load();

                    hidePopup();
                }
            }
            else { notify('Errore fatale durante il salvataggio', 'error', 2000); }



        }



    }, [isNewRecord, formData, hidePopup]);


    const confirmBtnOptions = useMemo(() => {
        return {
            text: "Salva",
            type: "success",
            onClick: confirmChanges
        }
    }, [confirmChanges]);

    const cancelBtnOptions = useMemo(() => {
        return {
            text: "Annulla",
            onClick: hidePopup
        }
    }, [hidePopup]);

    const editRow = useCallback((e) => {
        showPopup(false, { ...e.row.data });
    }, [showPopup]);

    const addRow = useCallback(async () => {

        var hasWarningAmministrativo;

        await CheckHasWarningAmministrativo().then(function (result) {
            hasWarningAmministrativo = result;
        });

        if (hasWarningAmministrativo) {
            let customDeleteConfirmDialog = custom({
                showTitle: false,
                messageHtml: "Impossibile creare nuove richieste. Presente un warning amministrativo per l'utente corrente.",
                buttons: [{
                    text: "Ok",
                    onClick: () => true
                }]
            })

            await customDeleteConfirmDialog.show().then((dialogResult) => {
                if (dialogResult) {
                    return;
                }
            }).then(function () {
                return;
            })
        }
        else {

            if (hasWarningAmministrativo == null) {
                notify('Errore durante l\'apertura della nuova richiesta', 'error', 2000);
            } else if (!hasWarningAmministrativo) {
                showPopup(true, {});
            }
        }
    }, [showPopup]);

    function clearLocalStorage() {
        let customDeleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: "Le personalizzazioni della griglia verrano eliminate. Sicuro di procedere voler procedere?",
            buttons: [{
                text: "Si",
                onClick: () => true
            }, {
                text: "No",
                onClick: () => false
            }]
        })

        customDeleteConfirmDialog.show().then((dialogResult) => {
            if (dialogResult) {
                gridRef.current.instance.state(null);
            }
        }).then(function () {
            dataSource.reload();
        })

    }

    function deleteRow(e) {
        let customDeleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: "Sicuro di voler rimuore questa richiesta? Verranno eliminati anche eventuali file allegati.",
            buttons: [{
                text: "Si",
                onClick: () => true
            }, {
                text: "No",
                onClick: () => false
            }]
        })

        customDeleteConfirmDialog.show().then((dialogResult) => {
            if (dialogResult) {
                store.remove(e.row.data.ID);
            }
        }).then(function () {
            dataSource.reload();
        })
    }

    function deleteRowAllegati(e) {
        let customDeleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: "Sicuro di voler rimuore questo allegato?",
            buttons: [{
                text: "Si",
                onClick: () => true
            }, {
                text: "No",
                onClick: () => false
            }]
        })

        customDeleteConfirmDialog.show().then((dialogResult) => {
            if (dialogResult) {
                storeAllegati.remove(e.row.data);
            }
        }).then(function () {
            dataSourceAllegati.reload();
        })
    }

    function getUrlUpload() {
        return url + '/api/RichiesteAllegati/Post?internalKey=' + user.internalKey + '&idRichiesta=' + (isNewRecord ? 0 : formData.ID) + '&newid=' + newGuid;
    }

    function getBadgeNote() {
        return (formData.NOTE_CLIENTE ?? '').trim().length > 0 ? '1' : null;
    }

    function getBadgeAllegati() {
        return null;
    }

    function onChangeFileUpload(result, value) {
        dataSourceAllegati.reload();
    }

    function GetTitlePopup() {
        return isNewRecord ? 'Nuova richiesta ' + (getPageInfo().pageType === '1' ? 'intervento' : 'preventivo') : 'Dettaglio richiesta ' + (getPageInfo().pageType === '1' ? 'intervento' : 'preventivo') + ' numero ' + formData.NUMERO

    }

    function onTitleClick(e) {
        if (e.itemIndex === 1) {
            dataSourceAllegati.reload();
        }
    }

    //END EDIT FORM  

    // FILTER GRID

    function statusSelected(e) {
        const selectedItemKeys = e.component.option("selectedItemKeys");

        var pageInfo = (getPageInfo().pageType === '1' ? 'storageRichiestaInterventoButtons' : 'storageRichiestaPreventivoButtons');

        let array = [];
        let selectedNewItemKey = [];

        selectedItemKeys.forEach(function (element) {
            array.push(['WF_STATO_CALC', '=', element]);
            array.push('or');
            selectedNewItemKey.push(element);
        });
        array.pop();

        localStorage.setItem(pageInfo, JSON.stringify(selectedNewItemKey));

        gridRef.current.instance.filter(array);
    }

    function onContentReady(e) {
        var pageInfo = (getPageInfo().pageType === '1' ? 'storageRichiestaInterventoButtons' : 'storageRichiestaPreventivoButtons');

        var x = localStorage.getItem(pageInfo);

        let array = [];

        if (x == null) {
            statusDefaultSelected.forEach(function (element) {
                array.push(['WF_STATO_CALC', '=', element]);
                array.push('or');
            });
            array.pop();
        }
        else {
            (JSON.parse(x)).forEach(function (element) {
                array.push(['WF_STATO_CALC', '=', element]);
                array.push('or');
            });
            array.pop();
        }

        gridRef.current.instance.filter(array);

    }

    function onInitializedButtonGroup(e) {
        var pageInfo = (getPageInfo().pageType === '1' ? 'storageRichiestaInterventoButtons' : 'storageRichiestaPreventivoButtons');

        var x = localStorage.getItem(pageInfo);

        if (x == null) {
            e.component.option("selectedItemKeys", statusDefaultSelected);
        }
        else {
            e.component.option("selectedItemKeys", JSON.parse(x));
        }
    }

    function customValidation(params) {
        var result = true;

        if (showTab) {
            if (params.formItem.dataField == 'PIVA') {
                if (params.value.length == 0) {
                    result = ((formData.CODFISC || '').toString() + (params.value || '').toString()) != ''
                } else if (params.value.length == 11 && params.value.match('^[0-9]*$')) {
                    result = ((formData.CODFISC || '').toString() + (params.value || '').toString()) != ''
                }
                else {
                    return false;
                }
            }
            if (params.formItem.dataField == 'CODFISC') {
                if (params.value.length == 0) {
                    result = ((formData.PIVA || '').toString() + (params.value || '').toString()) != ''
                } else if (((params.value.length == 11 && params.value.match('^[0-9]*$') || (params.value.length == 16 && params.value.match('^[a-zA-Z0-9]*$'))))) {
                    result = ((formData.PIVA || '').toString() + (params.value || '').toString()) != ''
                }
                else {
                    return false;
                }

            }
            if (params.formItem.dataField == 'RAGIONE_SOCIALE') {
                result = ((formData.COGNOME || '').toString() + (params.value || '').toString()) != ''
            }
            if (params.formItem.dataField == 'COGNOME') {
                result = ((formData.RAGIONE_SOCIALE || '').toString() + (params.value || '').toString()) != ''
            }
            if (params.formItem.dataField == 'CAP') {
                result = (params.value.match('^[0-9]*$') && (params.value.length == 5 || params.value.length == 0));
            }
            if (params.formItem.dataField == 'PROVINCIA') {
                result = (params.value.match('^[a-zA-Z]*$') && (params.value.length == 2 || params.value.length == 0));
            }
            if (params.formItem.dataField == 'TEL_FISSO') {
                result = ((formData.TEL_MOBILE || '').toString() + (params.value || '').toString()) != ''
            }
            if (params.formItem.dataField == 'TEL_MOBILE') {
                result = ((formData.TEL_FISSO || '').toString() + (params.value || '').toString()) != ''
            }
        }
        return result;
    }
    // END FILTER GRID 

    return (
        <React.Fragment>
            <h2 className={'content-block'}>{getPageInfo().pageTitle}</h2>

            <DataGrid
                id='gridContainer'
                className={'dx-card wide-card'}
                dataSource={dataSource}
                ref={gridRef}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                repaintChangesOnly={true}
                allowColumnResizing={true}
                useNative={true}
                allowColumnReordering={true}
                onContentReady={onContentReady}
            >
                <Scrolling showScrollbar='always' mode='standard'></Scrolling>
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} showNavigationButtons={true} visible={true} />
                <Paging defaultPageSize={10} />
                <HeaderFilter visible={true} />
                <FilterRow visible={true} />
                <FilterPanel visible={true} />
                <ColumnChooser enabled={true} mode="select" height={600} width={320} allowSearch={false} />
                <GroupPanel visible={true} emptyPanelText="Trascinare qui le intestazioni di colonna per creare raggruppamenti..." />
                <StateStoring enabled={true} type="localStorage" storageKey={(getPageInfo().pageType === '1' ? 'storageRichiestaIntervento' : 'storageRichiestaPreventivo')} />

                <Column dataField={'ID'} width={90} visible={false} />

                <Column type="buttons" width={80} caption="AZIONI" fixed={true} fixedPosition="left" >
                    <Button name="edit" visible={onAllowEditing} onClick={editRow} />
                    <Button name="delete" visible={onAllowDeleting} onClick={deleteRow} />
                </Column>
                <Column caption='IMPIANTO' alignment={"center"}>
                    <Column
                        dataField={'ID_IMPIANTO'}
                        caption={'Descrizione'}
                        visible={true}
                        minWidth={220}
                    >
                        <Lookup dataSource={impianti} valueExpr="Value" displayExpr="Text" />
                    </Column>
                </Column>
                <Column caption='RICHIESTA' align='center'>
                    <Column
                        dataField={'NUMERO'}
                        caption={'Numero'}
                        width={105}
                        visible={true}
                        sortOrder="desc"
                        allowEditing={false}
                        minWidth={105}
                        maxWidth={105}
                    />
                    <Column
                        dataField={'ID_TIPO_URGENZA'}
                        width={110}
                        caption={'Urgenza'}
                        visible={true}
                    >
                        <Lookup dataSource={tipiUrgenza} valueExpr="Value" displayExpr="Text" />
                    </Column>
                    <Column
                        dataField={'DATA_CREAZIONE'}
                        width={120}
                        caption={'Data creazione'}
                        dataType="date"
                        visible={true}
                        format="dd-MM-yyyy"
                    />
                    <Column
                        dataField={'DATA_RICHIESTA'}
                        width={120}
                        caption={'Data proposta dal cliente'}
                        dataType="date"
                        visible={true}
                        format="dd-MM-yyyy"
                    />
                    <Column
                        dataField={'ID_TIPO_PAGAMENTO'}
                        width={200}
                        caption={'Tipo pagamento'}
                        visible={true}
                    >
                        <Lookup dataSource={tipiPagamento} valueExpr="Value" displayExpr="Text" />
                    </Column>
                    <Column
                        dataField={'DESCRIZIONE'}
                        columnAutoWidth={true}
                        caption={'Oggetto'}
                        visible={true}
                    />


                    <Column
                        dataField={'NOTE_CLIENTE'}
                        showInColumnChooser={false}
                        caption={'Annotazioni aggiuntive'}
                        visible={false}
                    />
                </Column>
                <Column
                    dataField={'WF_STATO_CALC'}
                    width={130}
                    caption={'Stato'}
                    visible={true}
                    fixed={true} fixedPosition="right"
                    cellRender={ColorCell}
                    allowFiltering={false}
                    allowResizing={false}
                    minWidth={130}
                    maxWidth={130}
                >
                    <Lookup dataSource={tipiStato} valueExpr="Value" displayExpr="Text" />
                </Column>

                <Toolbar>
                    <Item >

                        <ButtonGroup
                            className="multiple-selection-styling-mode"
                            defaultSelectedItemKeys={statusDefaultSelected}
                            keyExpr="value"
                            stylingMode="contained"
                            selectionMode="multiple"
                            onSelectionChanged={statusSelected}
                            onInitialized={onInitializedButtonGroup}
                        >
                            <Item text="Compilato" type="compilato" value="10"></Item>
                            <Item text="In lavorazione" type="inlavorazione" value="18"></Item>
                            <Item text="Sospeso" type="warning" value="3"></Item>
                            <Item text="Chiuso" type="success" value="4"></Item>
                            <Item text="Annullato" type="danger" value="8"></Item>
                        </ButtonGroup>

                        <ButtonToolbar icon="add" onClick={addRow} text="Nuova richiesta" stylingMode="text" />
                    </Item>
                    <Item name="columnChooserButton" />
                    <Item>
                        <ButtonToolbar id="clearLocalStora" icon="clearformat" onClick={clearLocalStorage} text=""  />

                        <Tooltip
                            target="#clearLocalStora"
                            showEvent="mouseenter"
                            hideEvent="mouseleave"
                            hideOnOutsideClick={false}
                        >
                            <div>Pulisci personalizzazione griglia</div>
                        </Tooltip>

                    </Item>
                </Toolbar>
            </DataGrid>


            {visible ? (

                <Popup
                    title={GetTitlePopup()}
                    hideOnOutsideClick={true}
                    visible={true}
                    width={900}
                    height={750}
                    onHiding={hidePopup}
                >
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={confirmBtnOptions}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={cancelBtnOptions}
                    />

                    <Form
                        id="form"
                        colCount={1}
                        encType="multipart/form-data"
                        validationGroup={validationGroupName}
                        formData={formData}
                    >

                        <Item itemType="group">
                            <TabbedItem CssClass="enlarged">
                                <TabPanelOptions height={615} scrollingEnabled={true} animationEnabled={true} swipeEnabled={false} selectedIndex={0} onTitleClick={onTitleClick} />
                                <Tab title="Testata" icon="user" colCount={3}>

                                    <EmptyItem colSpan={3}></EmptyItem>

                                    <Item dataField="ID_IMPIANTO" colSpan={3} editorType="dxSelectBox" editorOptions={{
                                        dataSource: impianti, valueExpr: 'Value', displayExpr: 'Text',
                                        onValueChanged: async (e) => {
                                            var hasWarningAmministrativo;

                                            await CheckHasWarningAmministrativo(e.value).then(function (result) {
                                                hasWarningAmministrativo = result;
                                            });

                                            if (hasWarningAmministrativo) {
                                                let customDeleteConfirmDialog = custom({
                                                    showTitle: false,
                                                    messageHtml: "Impossibile creare nuove richieste. Presente un warning amministrativo per l'utente corrente.",
                                                    buttons: [{
                                                        text: "Ok",
                                                        onClick: () => true
                                                    }]
                                                })

                                                await customDeleteConfirmDialog.show().then((dialogResult) => {
                                                    if (dialogResult) {
                                                        formData.ID_IMPIANTO = null;
                                                        setShow(!showTab);
                                                        setShow(showTab);
                                                        return;
                                                    }
                                                }).then(function () {
                                                    return;
                                                })
                                            }
                                            else {

                                                if (hasWarningAmministrativo == null) {
                                                    notify('Errore durante l\'apertura della nuova richiesta', 'error', 2000);
                                                    return;
                                                } else if (!hasWarningAmministrativo) {
                                                    if (showTab) {
                                                        setShow(!showTab);
                                                        setShow(showTab);
                                                        await changeIndirizzoInquilino(e.value);
                                                    }
                                                }
                                            }
                                        }
                                    }} >
                                        <Label text={`Descrizione impianto`} />
                                        <RequiredRule />
                                    </Item>
                                    <Item dataField="ID_TIPO_URGENZA" colSpan={1} editorType="dxSelectBox" editorOptions={{ dataSource: tipiUrgenza, valueExpr: 'Value', displayExpr: 'Text' }}>
                                        <Label text={`Urgenza`} />
                                        <RequiredRule />
                                    </Item>
                                    <Item dataField="ID_TIPO_PAGAMENTO" colSpan={1} editorType="dxSelectBox" editorOptions={{
                                        dataSource: tipiPagamento, valueExpr: 'Value', displayExpr: 'Text',
                                        onValueChanged: async (e) => {
                                            var idTipoPagamento = 0;

                                            await GetTipoPagamentoByCodice('PG003').then(function (result) {
                                                idTipoPagamento = result;
                                            });
                                            setShow(e.value == idTipoPagamento);
                                            if (e.value == idTipoPagamento) {
                                                await changeIndirizzoInquilino(formData.ID_IMPIANTO);
                                            }

                                        }
                                    }}>
                                        <Label text={`Tipo pagamento`} />
                                        <RequiredRule />
                                    </Item>
                                    {/* <Item dataField="WF_STATO" colSpan={1}/> */}
                                    <Item dataField="DATA_RICHIESTA" editorType="dxDateBox" colSpan={1} editorOptions={{ dateSerializationFormat: 'yyyy-MM-dd', displayFormat: 'dd-MM-yyyy' }} >
                                        <Label text={`Data proposta dal cliente`} />
                                        <RequiredRule />
                                    </Item>
                                    <Item dataField="DESCRIZIONE" editorType="dxTextArea" colSpan={3} editorOptions={{ height: 260 }}>
                                        <Label text={`Oggetto`} />
                                        <RequiredRule />
                                    </Item>
                                </Tab>

                                <Tab id="tabinquilino" title="Dati inquilino" icon="user" colCount={1} visible={showTab} >

                                    <EmptyItem colSpan={1}></EmptyItem>

                                    <GroupItem colCount={7}>

                                        <Item dataField="RAGIONE_SOCIALE" colSpan={5}>
                                            <Label text={`Ragione sociale`} />
                                            <CustomRule
                                                type="custom"
                                                message={"Inserire ragione sociale"}
                                                reevaluate={true}
                                                validationCallback={customValidation}
                                            />
                                        </Item>
                                        <Item dataField="PIVA" colSpan={2} editorType="dxTextBox" >
                                            <Label text={`Partita iva`} />
                                            <CustomRule
                                                type="custom"
                                                message={"Inserire partita iva valida"}
                                                reevaluate={true}
                                                validationCallback={customValidation}
                                            />
                                            {/* <PatternRule pattern={'^[0-9]*$'} message="Digitare una partita iva valida" validationRules='CustomRule' />
                                            <StringLengthRule min={11} max={11} message="Digitare una partita iva valida" validationRules='CustomRule' /> */}
                                        </Item>
                                    </GroupItem>

                                    <GroupItem colCount={7}>
                                        <Item dataField="ID_TITOLO_PERSONALE" colSpan={1} editorType="dxSelectBox" editorOptions={{ dataSource: titoliPersonali, valueExpr: 'Value', displayExpr: 'Text' }} >
                                            <Label text={`Titolo`} />
                                        </Item>
                                        <Item dataField="NOME" colSpan={2}>
                                            <Label text={`Nome`} />
                                        </Item>
                                        <Item dataField="COGNOME" colSpan={2}>
                                            <Label text={`Cognome`} />
                                            <CustomRule
                                                type="custom"
                                                message={"Inserire cognome"}
                                                reevaluate={true}
                                                validationCallback={customValidation}
                                            />
                                        </Item>
                                        <Item dataField="CODFISC" colSpan={2}>
                                            <Label text={`Codice fiscale`} />
                                            <CustomRule
                                                type="custom"
                                                message={"Inserire codice fiscale valido"}
                                                reevaluate={true}
                                                validationCallback={customValidation}
                                            />
                                            {/* <StringLengthRule min={11} max={16} message="Digitare un codice fiscale valido" validationRules="CustomRule" /> */}
                                        </Item>
                                    </GroupItem>

                                    <GroupItem colCount={7}>
                                        <Item dataField="INDIRIZZO" colSpan={5}>
                                            <Label text={`Indirizzo`} />
                                        </Item>
                                        <Item dataField="CIVICO" colSpan={1}>
                                            <Label text={`Civico`} />
                                        </Item>
                                        <Item dataField="CAP" colSpan={1}>
                                            <Label text={`CAP`} />
                                            <CustomRule
                                                type="custom"
                                                message={"Inserire CAP"}
                                                reevaluate={true}
                                                validationCallback={customValidation}
                                            />
                                            {/* <StringLengthRule min={5} max={5} message="Digitare CAP valido" validationRules="CustomRule" /> */}
                                        </Item>
                                    </GroupItem>

                                    <GroupItem colCount={7}>
                                        <Item dataField="COMUNE" colSpan={4}>
                                            <Label text={`Comune`} />
                                        </Item>
                                        <Item dataField="PROVINCIA" colSpan={1}>
                                            <Label text={`Provincia`} />
                                            <CustomRule
                                                type="custom"
                                                message={"Inserire provincia"}
                                                reevaluate={true}
                                                validationCallback={customValidation}
                                            />
                                        </Item>
                                        <Item dataField="TEL_FISSO" colSpan={2}>
                                            <Label text={`Telefono`} />
                                            <CustomRule
                                                type="custom"
                                                message={"Inserire telefono"}
                                                reevaluate={true}
                                                validationCallback={customValidation} />
                                        </Item>
                                    </GroupItem>

                                    <GroupItem colCount={6}>

                                        <Item dataField="TEL_MOBILE" colSpan={2}>
                                            <Label text={`Cellulare`} />
                                            <CustomRule
                                                type="custom"
                                                message={"Inserire cellulare"}
                                                reevaluate={true}
                                                validationCallback={customValidation} />
                                        </Item>
                                        <Item dataField="MAIL" colSpan={2}>
                                            <Label text={`E-Mail`} />

                                        </Item>
                                        <Item dataField="PEC" colSpan={2} >
                                            <Label text={`Pec`} />
                                        </Item>
                                    </GroupItem>
                                </Tab>

                                <Tab title="Allegati" icon="comment" badge={getBadgeAllegati()} >

                                    <ScrollView >
                                        <br></br>
                                        <FileUploaderForm showFileList={false} multiple={true} urlUpload={getUrlUpload()} dataField="Id" onChange={onChangeFileUpload} >
                                        </FileUploaderForm>

                                        <span className="note">Tipi di file attendibili: <span>Immagini, documenti office, file di testo.</span>.</span>

                                        <br></br><br></br>

                                        <DataGrid
                                            showBorders={true}
                                            columnAutoWidth={true}
                                            width={"auto"}
                                            height={290}
                                            dataSource={dataSourceAllegati}
                                            remoteOperations={false}
                                        >
                                            <Scrolling mode="virtual" />

                                            <Editing
                                                mode="row"
                                                useIcons={true}
                                                allowUpdating={false}
                                                allowAdding={false}
                                                allowDeleting={false}
                                                confirmDelete={false}
                                                confirmDeleteMessage={'Sicuro di voler rimuovere questo documento?'}
                                            ></Editing>


                                            <Column type="buttons" width={80} caption="Azioni">
                                                <Button icon="download" onClick={downloadFile} />
                                                <Button icon="trash" onClick={deleteRowAllegati} />
                                            </Column>
                                            <Column
                                                dataField={'NAME'}
                                                caption={'Nome file'}
                                                visible={true}
                                            />
                                            <Column
                                                dataField={'FILE_SIZE'}
                                                caption={'Dimensione (kb)'}
                                                width={150}
                                                visible={true}
                                            />
                                            <Column
                                                dataField={'DATA_CREATION'}
                                                caption={'Data invio'}
                                                width={100}
                                                visible={true}
                                            />
                                        </DataGrid>


                                    </ScrollView>

                                </Tab>

                                <Tab title="Note" icon="find" badge={getBadgeNote()}>
                                    <Item dataField="NOTE_CLIENTE" editorType="dxTextArea" colSpan={3} editorOptions={{ height: 450 }}>
                                        <Label text={`Annotazioni aggiuntive`} />
                                    </Item>
                                </Tab>
                            </TabbedItem>

                        </Item>

                    </Form>

                </Popup>
            ) : null}

        </React.Fragment>
    )
};