/* eslint-disable no-useless-escape */
import React, { useState, useCallback, useMemo, useRef } from 'react';
import appInfo from '../../app-info';
import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';
import { custom } from 'devextreme/ui/dialog';
import DataSource from 'devextreme/data/data_source';
import { ButtonGroup } from 'devextreme-react/button-group';
import notify from 'devextreme/ui/notify';
import 'devextreme-react/text-area';
import { on } from "devextreme/events";

import DataGrid, {
    Scrolling,
    Column,
    Pager,
    Paging,
    FilterRow,
    FilterPanel,
    StateStoring,
    HeaderFilter,
    Button,
    GroupPanel,
    ColumnChooser,
    Lookup,
    Toolbar,
    RequiredRule,
    SearchPanel,
    Selection
} from 'devextreme-react/data-grid';

import { Tooltip } from 'devextreme-react/tooltip';
import ButtonToolbar from 'devextreme-react/button';
import Form, { Item, EmptyItem, TabbedItem, Tab, TabPanelOptions, GroupItem, Label } from 'devextreme-react/form';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import { CustomRule } from 'devextreme-react/validator';
import { useAuth } from '../../contexts/auth';
import { createStore } from "devextreme-aspnet-data-nojquery";

import validationEngine from 'devextreme/ui/validation_engine';
import ColorCell from './ColorCell.js';
import Banner from '../../components/banners/banner.js';
import BannerWarning from '../../components/banners/banner-warning.js';
import BannerRichiesta from '../../components/banners/banner-richiesta.js';

import './RichiesteCallCenter.scss';


export default function App(props) {

 

    const validationGroupName = "gridForm";
    const statusDefaultSelected = ['10', '18', '3', '4', '8'];

    const url = appInfo.urlWebApi;
    const { user } = useAuth();
    const gridRef = useRef();
    const gridRefImpianto = useRef();
    const tooltipRef = useRef();

    const [seed, setSeed] = useState(1);
    const [{ isNewRecord, visible, readOnly }, setPopupState] = useState({});
    const [formData, setData] = useState(null);
    const [formDataOld, setDataOld] = useState(null);
    const [richiestaPresente, setRichiestaPresente] = useState(false);
    const [updateRichiesta, setUpdateRichiesta] = useState(false);
    const [visibleSearchImpianto, setPopupSearchState] = useState(false);
    const [dataTooltip, setDataTooltip] = useState(null);

    const [{ showmessageBanner, showState }, setShowmessageBanner] = useState({});
    const eShowMessageBanner = useCallback(async (messageBanner) => {
        if (messageBanner != null) {
            setShowmessageBanner({ showmessageBanner: messageBanner, showState: true });
        } else {
            setShowmessageBanner({ showmessageBanner: messageBanner, showState: false });
        }
    }, []);

    const store = new CustomStore({
        key: 'ID',
        load: (loadOptions) => {
            return axios.get(url + '/api/RichiesteCallCenter/Get?', { params: { internalKey: user.internalKey, id: user.id } });
        },
        insert: (values) => {
            console.log(values);
            return axios.post(url + '/api/RichiesteCallCenter/Post?', null, { params: { values: JSON.stringify(values), internalKey: user.internalKey, userid: user.id } });
        },
        update: (key, values) => {
            console.log(key);
            console.log(values);
            return axios.put(url + '/api/RichiesteCallCenter/Put?', values, { params: { key: key, internalKey: user.internalKey } });
        },
        remove: (key) => {
            return axios.delete(url + '/api/RichiesteCallCenter/Delete', { params: { key: key, internalKey: user.internalKey } });
        },
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });
    const dataSource = new DataSource({
        store: store,
        refreshMode: 'reshape'
    });


    const storeImpianti = new CustomStore({
        key: 'ID',
        load: (loadOptions) => {
            return axios.get(url + '/api/RichiesteCallCenter/GetImpianti?', { params: { internalKey: user.internalKey, id: user.id } });
        },
        onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });
    const dataSourceImpianti = new DataSource({
        store: storeImpianti,
        refreshMode: 'reshape'
    })

    const tipiStato = createStore({
        key: 'Value',        
        loadUrl: `${url}/api/RichiesteCallCenter/AN_WF_STATILookup?internalKey=${user.internalKey}`,
        onBeforeSend(method, ajaxOptions) {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    const tipiChiamante = createStore({
        key: 'Value',
        type: 'array',
        loadUrl: `${url}/api/RichiesteCallCenter/TipiChiamanteLookup`,
        onBeforeSend(method, ajaxOptions) {
            ajaxOptions.xhrFields = { withCredentials: false };
        },
    });

    

    function setStateShow(state) {
        return state;
    }

    function onAllowEditing(e) {
        return (e.row.data.WF_STATO_CALC === 10);
    }
    function onAllowShowing(e) {
        return (e.row.data.WF_STATO_CALC !== 10);
    }
    function onAllowDeleting(e) {
        return (e.row.data.WF_STATO_CALC === 10);
    }

    function getBadgeNote() {
        return (formData.NOTE_CLIENTE ?? '').trim().length > 0 ? '1' : null;
    }


    //INIT EDIT FORM

    async function CheckRichiesteIntervento(idImpianto = null) {
        if (user) {
            const instance = axios.create({
                baseURL: url,
                timeout: 3000,
                headers: { 'X-Custom-Header': 'foobar' }
            });

            var value = null;

            await instance.get('/api/RichiesteCallCenter/CheckRichiesteIntervento', {
                params: {
                    internalKey: user.internalKey,
                    id: user.id,
                    idImpianto: idImpianto
                }
            })
                .then(function (response) {
                    value = response.data;
                })
                .catch(function () {
                    // imgLogo = { isOk: false, message: "Errore di connessione al servizio Web", data: null };
                })
                .finally(function () { });

            return value;
        }
        else {
            return null;
        }
    }

    const showPopup = useCallback(async (isNewRecord, formData, readOnly) => {      
        setData(formData);
        setPopupState({ isNewRecord, visible: true, readOnly: readOnly });
        setUpdateRichiesta(false);
    }, []);

    const hidePopup = useCallback(() => {
        setPopupState({ visible: false });
    }, []);

    const showPopupSearchImpianto = useCallback(async () => {
        setPopupSearchState(true);

    }, []);
    const hidePopupSearchImpianto = useCallback(() => {
        setPopupSearchState(false);
    }, []);


    const confirmChanges = useCallback(async () => {
        const result = validationEngine.validateGroup(validationGroupName);
        console.log('confirmChanges');
        if (!result.isValid)
            return;

        console.log(formData);
        var resultDati = null;

        console.log(isNewRecord);
        if (isNewRecord) {
            resultDati = await store.insert(formData);
        }
        else {
            resultDati = await store.update(formData["ID"], formData);
        }

        if (resultDati != null) {
            if (resultDati.data.result == 99) {
                notify(resultDati.data.message, 'error', 2000);
            } else {
                await store.load();

                hidePopup();
            }
        }
        else {
            notify('Errore fatale durante il salvataggio', 'error', 2000);
        }

    }, [isNewRecord, formData, hidePopup]);


    const confirmImpiantoChanges = useCallback(async () => {

        var hasRichiesteIntervento = false;
        var oldData;
        var row = gridRefImpianto.current.instance.getSelectedRowsData()[0];

        if (row !== null) {
            await CheckRichiesteIntervento(row.ID).then(function (result) {
                console.log(result);
                hasRichiesteIntervento = result.ID != null;
                oldData = result;
            });

            if (hasRichiesteIntervento) {

                setRichiestaPresente(true);

                var messageBanner = oldData.DESCRIZIONE;
                setDataOld(oldData);

                eShowMessageBanner(messageBanner);
                formData["ID_IMPIANTO"] = row.ID;
                formData["DESCRIZIONE_IMPIANTO"] = row.DESCRIZIONE_CLIENTE + ' - ' + row.DESCRIZIONE_IMPIANTO;
                setSeed(Math.random());
                hidePopupSearchImpianto();
            } else {

                var messageBanner = row.DESCRIZIONE_WARNING_TECNICO;

                eShowMessageBanner(messageBanner);

                console.log(showmessageBanner);
                formData["ID_IMPIANTO"] = row.ID;
                formData["DESCRIZIONE_IMPIANTO"] = row.DESCRIZIONE_CLIENTE + ' - ' + row.DESCRIZIONE_IMPIANTO;

                setRichiestaPresente(false);
                setSeed(Math.random());
                hidePopupSearchImpianto();
            }
        }
    }, [seed, formData, formDataOld, richiestaPresente, hidePopupSearchImpianto]);

    const confirmBtnOptions = useMemo(() => {
        return {
            text: "Salva",
            type: "success",
            onClick: confirmChanges
        }
    }, [confirmChanges]);
    const cancelBtnOptions = useMemo(() => {
        return {
            text: "Annulla",
            onClick: hidePopup
        }
    }, [hidePopup]);

    const confirmImpiantoBtnOptions = useMemo(() => {
        return {
            text: "Seleziona",
            type: "success",
            onClick: confirmImpiantoChanges
        }
    }, [confirmImpiantoChanges]);

    const cancelImpiantoBtnOptions = useMemo(() => {
        return {
            text: "Annulla",
            onClick: hidePopupSearchImpianto
        }
    }, [hidePopupSearchImpianto]);

    const editRow = useCallback((e) => {
        showPopup(false, { ...e.row.data }, false);
    }, [showPopup]);

    const showRow = useCallback((e) => {
        showPopup(false, { ...e.row.data }, true);
    }, [showPopup]);

    const addRow = useCallback(async () => {

        var hasWarningAmministrativo = false;

        if (hasWarningAmministrativo) {
            let customDeleteConfirmDialog = custom({
                showTitle: false,
                messageHtml: "Impossibile creare nuove richieste. Presente un warning amministrativo per l'utente corrente.",
                buttons: [{
                    text: "Ok",
                    onClick: () => true
                }]
            })

            await customDeleteConfirmDialog.show().then((dialogResult) => {
                if (dialogResult) {
                    return;
                }
            }).then(function () {
                return;
            })
        }
        else {

            if (hasWarningAmministrativo == null) {
                notify('Errore durante l\'apertura della nuova richiesta', 'error', 2000);
            } else if (!hasWarningAmministrativo) {
                eShowMessageBanner(null);
                showPopup(true, {}, false);
            }
        }
    }, [showPopup]);

    const buttonOptions = {
        text: "Cerca",
        type: "default",
        width: 200,
        onClick: function () {
            if (!(readOnly || updateRichiesta)) {
                showPopupSearchImpianto();
            }
            else {
                let customDeleteConfirmDialog = custom({
                    showTitle: false,
                    messageHtml: "Richiesta intervento in sola modifica impossibile modificare l'impianto.",
                    buttons: [{
                        text: "Ok",
                        onClick: () => true
                    }]
                })

                customDeleteConfirmDialog.show().then((dialogResult) => {
                    if (dialogResult) {
                        return;
                    }
                }).then(function () {
                    return;
                })
            }

        }
    };


    function refreshData() {
        dataSource.reload();
    }
    function clearLocalStorage() {
        let customDeleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: "Le personalizzazioni della griglia verrano eliminate. Sicuro di procedere voler procedere?",
            buttons: [{
                text: "Si",
                onClick: () => true
            }, {
                text: "No",
                onClick: () => false
            }]
        })

        customDeleteConfirmDialog.show().then((dialogResult) => {
            if (dialogResult) {
                gridRef.current.instance.state(null);
            }
        }).then(function () {
            dataSource.reload();
        });
    }
    function clearLocalStorageSearchImpianti() {
        let customDeleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: "Le personalizzazioni della griglia verrano eliminate. Sicuro di procedere voler procedere?",
            buttons: [{
                text: "Si",
                onClick: () => true
            }, {
                text: "No",
                onClick: () => false
            }]
        })

        customDeleteConfirmDialog.show().then((dialogResult) => {
            if (dialogResult) {
                gridRefImpianto.current.instance.state(null);
            }
        }).then(function () {
            dataSourceImpianti.reload();
        });
    }

    function deleteRow(e) {
        let customDeleteConfirmDialog = custom({
            showTitle: false,
            messageHtml: "Sicuro di voler rimuore questa richiesta?",
            buttons: [{
                text: "Si",
                onClick: () => true
            }, {
                text: "No",
                onClick: () => false
            }]
        })

        customDeleteConfirmDialog.show().then((dialogResult) => {
            if (dialogResult) {
                store.remove(e.row.data.ID);
            }
        }).then(function () {
            dataSource.reload();
        })
    }

    function GetTitlePopup() {
        return isNewRecord ? 'NUOVA RICHIESTA INTERVENTO' : 'DETTAGLIO RICHIESTA INTERVENTO NUMERO ' + formData.NUMERO

    }
    //END EDIT FORM  

    // FILTER GRID
    function statusSelected(e) {
        const selectedItemKeys = e.component.option("selectedItemKeys");

        var pageInfo = 'storageRichiestaInterventoCallCenterButtons';

        let array = [];
        let selectedNewItemKey = [];

        selectedItemKeys.forEach(function (element) {
            array.push(['WF_STATO_CALC', '=', element]);
            array.push('or');
            selectedNewItemKey.push(element);
        });
        array.pop();

        localStorage.setItem(pageInfo, JSON.stringify(selectedNewItemKey));

        gridRef.current.instance.filter(array);
    }
    function onContentReady(e) {
        var pageInfo = 'storageRichiestaInterventoCallCenterButtons';

        var x = localStorage.getItem(pageInfo);

        let array = [];

        if (x == null) {
            statusDefaultSelected.forEach(function (element) {
                array.push(['WF_STATO_CALC', '=', element]);
                array.push('or');
            });
            array.pop();
        }
        else {
            (JSON.parse(x)).forEach(function (element) {
                array.push(['WF_STATO_CALC', '=', element]);
                array.push('or');
            });
            array.pop();
        }

        gridRef.current.instance.filter(array);

    }
    function onInitializedButtonGroup(e) {
        var pageInfo = 'storageRichiestaInterventoCallCenterButtons'

        var x = localStorage.getItem(pageInfo);

        if (x == null) {
            e.component.option("selectedItemKeys", statusDefaultSelected);
        }
        else {
            e.component.option("selectedItemKeys", JSON.parse(x));
        }
    }

    function customValidation(params) {
        var result = true;
        if (hidePopup) {
            if (params.formItem.dataField == 'DESCRIZIONE_IMPIANTO') {
                result = ((formData.DESCRIZIONE_IMPIANTO || '').toString() + (params.value || '').toString()) != ''
            }
            if (params.formItem.dataField == 'DATI_CHIAMANTE') {
                result = ((formData.DATI_CHIAMANTE || '').toString() + (params.value || '').toString()) != ''
            }
            // if (params.formItem.dataField == 'TEL_CHIAMANTE') {
            //     result = (params.value.match('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$'));
            // }
            if (params.formItem.dataField == 'DESCRIZIONE') {
                result = ((formData.DESCRIZIONE || '').toString() + (params.value || '').toString()) != ''
            }
            if (params.formItem.dataField == 'TIPO_CHIAMANTE') {
                result = ((formData.TIPO_CHIAMANTE || '').toString() + (params.value || '').toString()) != ''
            }
        }
        return result;
    }
    // END FILTER GRID

    const onClickRichiesta = useCallback(async (e) => {
        setData(formDataOld);
        setPopupState({ isNewRecord: false, visible: true });

        setRichiestaPresente(false);
        setUpdateRichiesta(true);

        var messageBanner = formData.DESCRIZIONE_WARNING_TECNICO;

        eShowMessageBanner(messageBanner);
        setSeed(Math.random());
    });

    const onCellPrepared = useCallback(async (e) => {
        
        if (e.rowType === "data" && e.column.dataField === "CODICE_SEDE") {
            console.log(e);
          on(e.cellElement, "mouseover", arg => {
              setDataTooltip(e.data.DESCRIZIONE_SEDE);
              tooltipRef.current.instance.show(arg.target);
          });
    
          on(e.cellElement, "mouseout", arg => {
            tooltipRef.current.instance.hide();
          });
        }
      });


    return (
        <React.Fragment>
            <h2 className={'content-block'}>Elenco richieste interventi</h2>

            <DataGrid
                id='gridContainer'
                className={'dx-card wide-card'}
                dataSource={dataSource}
                ref={gridRef}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                repaintChangesOnly={true}
                allowColumnResizing={true}
                useNative={true}
                allowColumnReordering={true}
                onCellPrepared={onCellPrepared}
                onContentReady={onContentReady}>

                <Scrolling showScrollbar='always' mode='standard'></Scrolling>
                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} showNavigationButtons={true} visible={true} />
                <Paging defaultPageSize={10} />
                <HeaderFilter visible={true} />
                <FilterRow visible={true} />
                <FilterPanel visible={true} highlightCaseSensitive={"false"} />
                <ColumnChooser enabled={true} mode="select" height={600} width={320} allowSearch={false} />
                <GroupPanel visible={false} emptyPanelText="Trascinare qui le intestazioni di colonna per creare raggruppamenti..." />
                <StateStoring enabled={true} type="localStorage" storageKey={'storageRichiesteInterventoCallCenter'} />

                <Column type="buttons" width={80} caption="AZIONI" fixed={true} fixedPosition="left" >
                    <Button name="edit" Tooltip="Apri" visible={onAllowEditing} onClick={editRow} />
                    <Button icon="search" Tooltip="Apri" visible={onAllowShowing} onClick={showRow} />
                    <Button name="delete" Tooltip="Elimina" visible={onAllowDeleting} onClick={deleteRow} />
                </Column>

                <Column caption='CLIENTE / IMPIANTO' alignment={"center"} minWidth={500} maxWidth={800} width={800}>
                    <Column
                        dataField={'CODICE_SEDE'}
                        caption={'Sede'}
                        visible={true}
                        minWidth={85}
                        maxWidth={85}
                        width={85} />        
                    <Column
                        dataField={'DESCRIZIONE_IMPIANTO'}
                        caption={'Descrizione'}
                        visible={true}
                        minWidth={400}
                        maxWidth={600}
                        width={500} />
                    <Column
                        dataField={'INDIRIZZO_IMPIANTO'}
                        caption={'Indirizzo'}
                        visible={false}
                        minWidth={250}
                        maxWidth={600}
                        width={300} />
                    <Column
                        dataField={'CIVICO_IMPIANTO'}
                        caption={'Civico'}
                        visible={true}
                        minWidth={90}
                        maxWidth={90}
                        width={90} />
                    <Column
                        dataField={'COMUNE_IMPIANTO'}
                        caption={'Comune'}
                        visible={true}
                        minWidth={115}
                        maxWidth={180}
                        width={115} />
                    <Column
                        dataField={'PROVINCIA_IMPIANTO'}
                        caption={'Prov'}
                        visible={true}
                        minWidth={80}
                        maxWidth={80}
                        width={80} />

                </Column>
                <Column caption='RICHIESTA' align='center' minWidth={800}>
                    <Column
                        dataField={'NUMERO'}
                        caption={'Num.'}
                        visible={true}
                        sortOrder="desc"
                        allowEditing={false}
                        minWidth={100}
                        maxWidth={100}
                        width={100} />
                    <Column
                        dataField={'DATA_CREAZIONE'}
                        minWidth={150}
                        maxWidth={150}
                        width={150}
                        caption={'Data creazione'}
                        dataType="date"
                        visible={true}
                        format="dd/MM/yyyy HH:mm" />
                    <Column
                        dataField={'DESCRIZIONE'}                        
                        columnAutoWidth={true}
                        minWidth={500}
                        caption={'Oggetto'}
                        visible={true} />
                    <Column
                        dataField={'NOTE_CLIENTE'}
                        showInColumnChooser={false}
                        mminWidth={500}
                        caption={'Annotazioni aggiuntive'}
                        visible={false} />
                </Column>
                <Column
                    dataField={'WF_STATO_CALC'}
                    width={130}
                    caption={'Stato'}
                    visible={true}
                    fixed={true} fixedPosition="right"
                    cellRender={ColorCell}
                    allowFiltering={false}
                    allowResizing={false}
                    minWidth={130}
                    maxWidth={130}
                    allowHiding={false} showInColumnChooser={false} >

                    <Lookup dataSource={tipiStato} valueExpr="Value" displayExpr="Text" />
                </Column>

                <Toolbar>
                    <Item >
                        <ButtonGroup
                            className="multiple-selection-styling-mode"
                            defaultSelectedItemKeys={statusDefaultSelected}
                            keyExpr="value"
                            stylingMode="contained"
                            selectionMode="multiple"
                            onSelectionChanged={statusSelected}
                            onInitialized={onInitializedButtonGroup}>

                            <Item text="Compilato" type="compilato" value="10"></Item>
                            <Item text="In lavorazione" type="inlavorazione" value="18"></Item>
                            <Item text="Sospeso" type="warning" value="3"></Item>
                            <Item text="Chiuso" type="success" value="4"></Item>
                            <Item text="Annullato" type="danger" value="8"></Item>
                        </ButtonGroup>

                        <ButtonToolbar icon="add" onClick={addRow} text="Nuova richiesta" stylingMode="text" />
                    </Item>
                    <Item>
                        <ButtonToolbar id="refreshData" icon="refresh" onClick={refreshData} text="" />
                    </Item>
                    <Item name="columnChooserButton" />
                    <Item>
                        <ButtonToolbar id="clearLocalStora" icon="clearformat" onClick={clearLocalStorage} text="" />

                        <Tooltip target="#clearLocalStora" showEvent="mouseenter" hideEvent="mouseleave" hideOnOutsideClick={false}>
                            <div>Ripristina impostazioni layout predefinite</div>
                        </Tooltip>
                    </Item>
                </Toolbar>
            </DataGrid>
            <Tooltip ref={tooltipRef} position={'right'}>
                <div className={'tooltipContent'}>
                    <div><b>Sede:</b> {dataTooltip}</div>
                </div>
            </Tooltip>


            {visible ? (
                <Popup
                    id="popUpRichiesta"
                    key={seed}
                    title={GetTitlePopup()}
                    hideOnOutsideClick={true}
                    visible={true}
                    width={1050}
                    height={750}
                    onHiding={hidePopup}>

                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        visible={!readOnly}
                        options={confirmBtnOptions} />
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={cancelBtnOptions} />

                    <Form
                        id="form"
                        colCount={1}
                        encType="multipart/form-data"
                        validationGroup={validationGroupName}
                        formData={formData}>

                        <TabbedItem CssClass="enlarged">
                            <TabPanelOptions height={615} scrollingEnabled={true} animationEnabled={true} swipeEnabled={false} selectedIndex={0} />

                            <Tab title="DATI PRICIPALI" icon="comment">

                                <Item colCount={9} >
                                    {
                                        readOnly ?
                                            <Banner stato={formData.WF_STATO_CALC} /> :
                                            showState ?
                                                (richiestaPresente ? <BannerRichiesta text={showmessageBanner} formData={formData} onClick={onClickRichiesta} /> : <BannerWarning text={showmessageBanner} />)
                                                : null
                                    }
                                </Item>

                                <GroupItem colCount={9}>

                                    <Item dataField="DESCRIZIONE_IMPIANTO" colSpan={7} editorOptions={{ readOnly: true }} >
                                        <Label text={'CLIENTE / IMPIANTO'} />
                                        <CustomRule
                                            type="custom"
                                            message={"Selezionare il cliente"}
                                            reevaluate={true}
                                            validationCallback={customValidation} />
                                        <RequiredRule />
                                    </Item>
                                    <Item
                                        itemType="button"
                                        colSpan={2}
                                        cssClass="cercaInpianto"
                                        buttonOptions={buttonOptions} />
                                </GroupItem>

                                <GroupItem colCount={9}>
                                    <Item dataField="DATI_CHIAMANTE" colSpan={5} editorOptions={{ maxLength: 255, readOnly: readOnly || updateRichiesta }}>
                                        <Label text={'CHIAMANTE'} />
                                        <CustomRule
                                            type="custom"
                                            message={"Indicare nome e cognome della persona che fatto la richiesta"}
                                            reevaluate={true}
                                            validationCallback={customValidation} />
                                        <RequiredRule />
                                    </Item>
                                    <Item dataField="TIPO_CHIAMANTE" colSpan={2} editorType="dxSelectBox" editorOptions={{ dataSource: tipiChiamante, valueExpr: 'Value', displayExpr: 'Text' , readOnly: readOnly || updateRichiesta}}>
                                        <Label text={`TIPO CHIAMANTE`} />
                                        <CustomRule
                                            type="custom"
                                            message={"Indicare il tipo chiamante che fatto la richiesta"}
                                            reevaluate={true}
                                            validationCallback={customValidation} />
                                        <RequiredRule />
                                    </Item>
                                    <Item dataField="TEL_CHIAMANTE" colSpan={2} editorOptions={{ maxLength: 50, readOnly: readOnly || updateRichiesta }}>
                                        <Label text={'TELEFONO'} />
                                        
                                        <RequiredRule />
                                    </Item>
                                </GroupItem>

                                <EmptyItem colSpan={1}></EmptyItem>

                                <Item dataField="DESCRIZIONE" editorType="dxTextArea" colSpan={9} editorOptions={{ height: (showState ? 181 : 254), readOnly: readOnly }}>
                                    <Label text={'OGGETTO DELLA RICHIESTA'} />
                                    <RequiredRule />
                                </Item>
                            </Tab>

                            <Tab title="NOTE" icon="find" badge={getBadgeNote()}>
                                <Item dataField="NOTE_CLIENTE" editorType="dxTextArea" colSpan={3} editorOptions={{ height: 450, maxLength: 4000, readOnly: readOnly }}>
                                    <Label text={'ANNOTAZIONI AGGIUNTIVE'} />
                                </Item>
                            </Tab>
                        </TabbedItem>

                    </Form>

                </Popup>
            ) : null}


            {visibleSearchImpianto ? (
                <Popup
                    title="Cerca impianto"
                    hideOnOutsideClick={true}
                    visible={true}
                    width={'100%'}
                    height={'100%'}
                    onHiding={hidePopupSearchImpianto}>
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={confirmImpiantoBtnOptions} />
                    <ToolbarItem
                        widget="dxButton"
                        location="after"
                        toolbar="bottom"
                        options={cancelImpiantoBtnOptions} />

                    <div className='grid-height-search-impianto'>
                        <DataGrid
                            id='gridImpianti'
                            ref={gridRefImpianto}
                            dataSource={dataSourceImpianti}
                            showBorders={false}
                            focusedRowEnabled={true}
                            defaultFocusedRowIndex={0}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            allowColumnReordering={true}>

                            <Scrolling showScrollbar='always' mode='standard'></Scrolling>
                            <Selection mode="single" />
                            <HeaderFilter visible={true} />
                            <FilterRow visible={true} />
                            <FilterPanel visible={false} />
                            <ColumnChooser enabled={true} mode="select" />
                            <GroupPanel visible={true} emptyPanelText="Trascinare qui le intestazioni di colonna per creare raggruppamenti..." />
                            <Paging defaultPageSize={10} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} showNavigationButtons={true} visible={true} />
                            <SearchPanel visible={true} width={"500px"} />

                            <StateStoring enabled={true} type="localStorage" storageKey="storageSearchImpianto" />

                            {/*<Column type="buttons" visible={false} width={80} caption="Status" fixed={true} fixedPosition="left">*/}
                            {/*    */}{/* <Button hint="Warning amministrativo" icon="warning" visible={isWarningAmministrativoIconVisible} /> */}
                            {/*</Column>*/}

                            <Column dataField={'ID'} width={90} visible={false} allowHiding={false} showInColumnChooser={false} />

                            <Column
                                dataField={'DESCRIZIONE_SEDE'}
                                caption={'Sede'}
                                visible={true}
                                minWidth={150}
                                maxWidth={300}
                                width={180} />
                            <Column
                                dataField={'DESCRIZIONE_CLIENTE'}
                                caption={'Cliente'}
                                visible={true}
                                sortOrder="asc"
                                allowSearch={true}
                                minWidth={350}
                                maxWidth={600}
                                width={400} />
                            <Column
                                dataField={'DESCRIZIONE_IMPIANTO'}
                                caption={'Impianto'}
                                visible={true}
                                minWidth={400}
                                maxWidth={600}
                                width={500} />
                            <Column
                                dataField={'INDIRIZZO_IMPIANTO'}
                                caption={'Indirizzo'}
                                minWidth={300}
                                maxWidth={600}
                                width={350}
                                visible={true}
                                allowSearch={true} />
                            <Column
                                dataField={'CIVICO_IMPIANTO'}
                                caption={'Civico'}
                                minWidth={100}
                                maxWidth={100}
                                width={100}
                                visible={true}
                                allowSearch={true} />
                            <Column
                                dataField={'COMUNE_IMPIANTO'}
                                caption={'Comune'}
                                minWidth={150}
                                maxWidth={400}
                                width={200}
                                visible={true}
                                allowSearch={true} />
                            <Column
                                dataField={'PROVINCIA_IMPIANTO'}
                                caption={'Prov.'}
                                minWidth={90}
                                maxWidth={90}
                                width={90}
                                visible={true}
                                allowSearch={true} />

                            <Toolbar>
                                <Item id="searchPanel" name="searchPanel" location={'left'} cssClass="toolbarSearch" />
                                <Item name="columnChooserButton" />
                                <Item>
                                    <ButtonToolbar id="clearLocalStoredImpianto" icon="clearformat" onClick={clearLocalStorageSearchImpianti} text="" />
                                    <Tooltip
                                        target="#clearLocalStora"
                                        showEvent="mouseenter"
                                        hideEvent="mouseleave"
                                        hideOnOutsideClick={false}>
                                        <div>Pulisci personalizzazione griglia</div>
                                    </Tooltip>
                                </Item>
                            </Toolbar>

                        </DataGrid>

                    </div>

                </Popup>
            ) : null}

        </React.Fragment>
    )
};