import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { useAuth } from './contexts/auth';

function setHomePAge(user, target) {    
    var response;

    if (user.userType === 4) { response = '/richiesteCallCenter'; }
    else {
        if (target == null) { response = '/home'; }
        else { response = '/flussoApprovativo'; }
    }

    return response;
}

export default function () {

    const queryParameters = new URLSearchParams(window.location.search);
    const username = queryParameters.get("a");
    const pwd = queryParameters.get("b");
    const target = queryParameters.get("c");

    const { user } = useAuth();

    return (
        <SideNavBarLayout title={`${appInfo.title} v.2.61 -  ${appInfo.company}`} >
            <Switch>
                {routes.map(({ path, component }) => (
                    <Route
                        exact
                        key={path}
                        path={path}
                        component={component}
                    />
                ))}
                <Redirect to={setHomePAge(user, target)} />
            </Switch>
            <Footer>
                Copyright © 2011-{new Date().getFullYear()} {appInfo.title} Inc.
            </Footer>
        </SideNavBarLayout>
    );
}