
import React from 'react';
import { Popover } from 'devextreme-react/popover';
import './style-banner.css';


class App extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="rcorners2" class="header">
                <p>Richiesta in stato <b>{this.getStatoRichiesta()}</b>, non è più possible modificare la richiesta.</p>
            </div>
        )
    }

    getStatoRichiesta = (e) => {
        console.log('qui')
        console.log(this.props)
        let stato;
        switch (this.props.stato) {
            case 10:
                stato = "COMPILATO";
                break;
            case 18:
                stato = "IN LAVORAZIONE";
                break;
            case 3:
                stato = "SOSPESO";
                break;
            case 4:
                stato = "CHIUSO";
                break;
            case 8:
                stato = "ANNULLATO";
                break;
            default:
                stato = "";
        }
        console.log('stato')
        return stato;
    }

}

export default App;